// Settled on moving the import of syntax highlighting to the bottom of the CSS
// ... Otherwise it really bloats up the top of the CSS file and makes it difficult to find the start
@import "highlights";

.footnote {
  vertical-align: super;
  font-size: smaller;
}

abbr[title] {
  border-bottom: 1px dotted;
}

/*
 * Pagination
 *
 * Super lightweight (HTML-wise) blog pagination. `span`s are provide for when
 * there are no more previous or next posts to show.
 */

.pagination {
  overflow: hidden; /* clearfix */
  margin-left: -1rem;
  margin-right: -1rem;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  color: #ccc;
  text-align: center;
}

/* Pagination items can be `span`s or `a`s */
.pagination-item {
  display: block;
  padding: 1rem;
  border: 1px solid #eee;
}
.pagination-item:first-child {
  margin-bottom: -1px;
}

/* Only provide a hover state for linked pagination items */
a.pagination-item:hover {
  background-color: #f5f5f5;
}

@media (min-width: 30em) {
  .pagination {
    margin: 3rem 0;
  }
  .pagination-item {
    float: left;
    width: 33%;
  }
  .pagination-item:first-child {
    margin-bottom: 0;
    border-top-left-radius:    4px;
    border-bottom-left-radius: 4px;
  }
  .pagination-item:last-child {
    margin-left: -1px;
    border-top-right-radius:    4px;
    border-bottom-right-radius: 4px;
  }
}